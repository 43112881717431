import React, { useState } from "react"
import styled from "styled-components"
import { Link, graphql  } from "gatsby"
import { faChevronRight, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import Helmet from "react-helmet"

import Container from "../components/layout/Container"
import Page from "../components/layout/Page"
import PageHeader  from "../components/layout/PageHeader"
import Layout2Columns from "../components/layout/Layout2Columns"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import VideoPreview from "../components/VideoPreview"
import HoverButton from "../components/buttons/HoverButton"
import PhotoGrid from "../components/PhotoGrid"
import Video from "../components/Video"

import placeholderImg from "../images/placeholder.jpg"
import { colors, devices, storage, social, reviews } from "../settings"

import FacebookIcon from '../images/icons/facebook.png'
import InstagramIcon from '../images/icons/instagram.png'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { isMobile } from "react-device-detect"

const ContentGrid = styled.div`
    > div {
        margin-bottom: 10px;
    }

    &.right {
        flex-flow: row-reverse;

        div.image {
            padding-left: 0px;
        }
        div.info {
            padding-right: 0px;

            > div {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: 0px;
            }
        }
    }
    &.left {
        div.image {
            padding-right: 0px;
        }
        div.info {
            padding-left: 0px;

            > div {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-left: 0px;
            }
        }
    }

    div.image {
        @media ${devices.mobile} {
            padding: 8px !important;
        }

        img {
            box-shadow: 0px 6px 10px #ccc;

            @media ${devices.mobile} {
                box-shadow: none;
            }
        }
    }
    div.info {
        padding: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
    
        @media ${devices.mobile} {
            padding: 0px 8px !important;
            margin-top: -20px;
            margin-bottom: 15px;
        }

        > div {
            border: 1px solid #ccc;
            border-radius: 5px;
            box-shadow: 0px 6px 10px #ccc;

            padding: 20px;
            margin-top: 25px;
            background-color: white;

            @media ${devices.mobile} {
                margin-top: 0px;
                border-bottom-right-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
                border-top-right-radius: 0px !important;
                border-top-left-radius: 0px !important;
            }

            h3 {
                margin-top: 0px;
                font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
            }
        }
    }
  
    p {
        margin-top: 0px;
    }

`
const Content1 = styled.div`
    > div {
        padding-left: 20px;  
        
        @media ${devices.tablet} {
           padding-left: 10px;
        }
        @media ${devices.mobile} {
           padding-left: 0px;
        }
    }

    button {
        margin-top: 25px;

        @media ${devices.mobile} {
            margin-top: 0px;
        }
    }
`
const Content2 = styled.div`
    border-top: 3px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    background-image: url(${storage.url}2020/tiles/grey3.jpg);
    margin-top: 100px;
    padding-bottom: 50px;

    @media ${devices.tablet} {
        margin-top: 30px;
    }    
    @media ${devices.mobile} {
        margin: 0px -10px;
        padding-bottom: 0px;
    }
`
const Content3 = styled.div`
    border-top: 3px solid #e8e8e8;
    background-image: url(${storage.url}2020/tiles/grey3med.jpg);
    background-repeat: repeat-x;
    padding: 30px 0px;

    @media ${devices.mobile} {
        margin: 0px -10px;
        padding: 0px;
    }
`
const Content4 = styled.div`
    border-top: 3px solid #e8e8e8;
    background-image: url(${storage.url}2020/tiles/grey3lrg.jpg);
    background-repeat: repeat-x;
    padding: 30px 0px;

    @media ${devices.mobile} {
        margin: 0px -10px;
        padding: 10px;
    }
`
const OptionalContent = styled.div`
    h2, h3 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
    h2 {
        @media ${devices.mobile} {
            text-align: center;
        }
    }
    > div {
        margin-left: 25px;

        @media ${devices.mobile} {
            margin: 10px;
        }

        > div {
            margin-left: 25px;
            display: flex;

            @media ${devices.mobile} {
                display: block;
                margin-left: 0px;
            }
        }
        img {
            display: block;
            margin: 20px auto;
            max-height: 200px;
            max-width: 500px;
            width: auto;

            @media ${devices.mobile} {
                width: 90%;
            }
        }
    }
    a {
        display: block;
        margin-top: 15px;
    }
`
const ContentImage = styled.img`
    width: 100%;
    border-radius: 5px;

    @media ${devices.mobile} {
        margin-top: 10px;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
`
const Videos = styled.div`
    display: flex;
    justify-content: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin: 0px;
    margin-top: -100px;
    margin-bottom: 50px;
    box-shadow: 0px 8px 8px #ccc;

    @media ${devices.tablet} {
        margin: 10px;
    }
    @media ${devices.mobile} {
        display: block;
    }

    h3 {
        margin: 0px;
    }

    > .video {
        flex: 1;
        flex-basis: 33%;
        max-width: 33%;
        padding: 2px;

        @media ${devices.mobile} {
            flex-basis: 100%;
            max-width: 100%;
        }

        > * {
            flex-basis: 50%;
        }
    }
`
const Quote = styled.div`
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px #ccc;

    margin: ${isMobile ? 10:0}px;
    
    > div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        color: #666;

        svg {
            margin-right: 10px;
            font-size: 28px;
        }
    }

    > p {
        font-size: 150%;
    }
`

export default function Product({ data }) {
    const [section, setSection] = useState("construction");

    const product = data.products.edges[0].product;
    const installVideos = data.installvideos.edges.map(e => e.video);//.map(iv => iv.video_url);

    function findContentImage(path) {
        var file = data.images.edges.find(x => x.content.path.indexOf(path) != -1);
        
        if (file)
          return file.content.image.fixed.src;  
        else
          return placeholderImg;
    }

    const isLit = product.title.indexOf("Lit") != -1;

    const structuredData = JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Product",
        "sku": product.name,
        "image": [
            storage.url + "2020/products/"+ product.name +".jpg"  
        ],
        "name": product.title,
        "description": product.description,
        "brand": {
            "@type": "Brand",
            "name": "Sign Monkey"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": reviews.rating,
            "reviewCount": reviews.count
        },   
        "review": {
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": "JMR Max LLC"
            },
            "datePublished": "2022-03-02",
            "reviewBody": "From one business to another, it was easy to tell right from the start that Sign Monkey is a business where all team members care about what they do and how customer relations are handled. Excellent job, excellent communications, and excellent service! Keep up the good work.",
            "name": "Value purchase",
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
            }
        }           
    })

    return (
        <>
            <SEO page="product" title={product.title} />

            <Helmet>
                <script type="application/ld+json">{structuredData}</script> 
            </Helmet>

            <Page 
                transparent
                padding={0}
                breadcrumb={[
                    {title: "Home", url:"/"},
                    {title: "Products", url:"/products/"},                    
                    {title: product.category, url: "/products/" + product.category_name + "/"},
                    {title: product.title, url: null}
                ]}
                containerProps={{
                    mode: "full"
                }}
                >

            <Content1>
                <Container>
                <PageHeader 
                    title={product.title}
                    subtitle={product.subtitle}
                />

                <Layout2Columns left={40} right={60}>
                    <div>
                        <p>{product.fulldescription || product.description}</p>

                        {(product.production_days > 5) && 
                            <p>
                                * Due to high volume sales our Production Time on {product.title} is {product.production_days} Working Days.
                            </p>
                        }

                        <Link to={"/builder/?from=ProductPage&product=" + product.name}>
                            <HoverButton
                                label="Design &amp; Price Now"
                                icon={faChevronRight}
                                color="red"
                            />
                        </Link>

                        {isLit && 
                            <div style={{marginTop: "15px"}}>
                                <GoogleImage src="ul.png" required lazy alt="UL Label" style={{float: "left", marginRight: "10px"}} />
                                All our signs carry the UL label and are tested and inspected
                                by a UL certified inspector.
                            </div>
                        }                        
                    </div>
                    <div>
                        <GoogleImage 
                            root 
                            src={"2020/products/"+ product.name +".jpg?v1"} 
                            required 
                            lazy
                            alt={product.title} 
                            style={{
                                maxWidth: "600px",
                                width: "100%"
                            }}
                        />
                    </div>
                </Layout2Columns>
            </Container>
            </Content1>

            <Content2>
                <Container width={900}>
                    <Videos style={{visibility: data.videos.edges.length ? "visible":"hidden"}}>
                        {data.videos.edges.map(({video}, index) => (
                            // <Video
                            //     key={index}
                            //     title={video.title}
                            //     video={video.video}
                            // />
                            <VideoPreview 
                                video_url={"https://www.youtube.com/embed/" + video.video}
                                image_url={video.preview_thumb_url} 
                                page_url={"/why/videos/" + video.name}
                            />                            
                        ))}
                    </Videos>

                    {data.content.edges.filter(e => e.content.optional_ind==0).map(({content}, index) => (
                        <ContentGrid className={index%2 == 0 ? "right":"left"}>
                        <Layout2Columns key={index} reverse={index%2 == 0}>
                            <div className="image">                        
                                <ContentImage src={storage.url + content.image_url} alt={content.title} />
                            </div>
                            <div className="info">
                                <div>
                                    <h3>{content.title}</h3>
                                    <div className="content" dangerouslySetInnerHTML={{__html: content.description}} />
                                </div>
                            </div>
                        </Layout2Columns>
                        </ContentGrid>
                    ))}
                </Container>
            </Content2>

            {(data.content.edges.filter(e => e.content.optional_ind==1).length > 0) && 
                <Container width={900}>
                    <OptionalContent>
                        <h2>Optional Items</h2>

                        {data.content.edges.filter(e => e.content.optional_ind==1).map(({content}, index) => (
                            <div key={index}>
                                <h3>{content.title}</h3>
                                <div className="content" dangerouslySetInnerHTML={{__html: content.description}} />
                                {(content.name != "alternative_mounting_methods_" && content.name != "alternative_installation_methods") &&
                                    <div>
                                        <Link to={"/products/" + product.url + "/" + content.shortname + "/"}>
                                            <HoverButton 
                                                color="blue"
                                                label="Learn More"
                                                icon={faChevronRight}
                                            />
                                        </Link>
                                        <ContentImage src={storage.url + content.image_url} alt={content.title} />
                                    </div>
                                }
                            </div>
                        ))}
                    </OptionalContent>
                </Container>
            }

            <br />
            <br />

            <Content3>
                <Container width={900}>
                    <ContentGrid>
                        <div>
                            <h2>
                                Easy Installation
                            </h2>
                            <p>
                                Our products are designed to be installed by people with limited {isLit && "electrical"} experience. {isLit && "UL labeled and National Electric Code Approved"}
                            </p>
                            <br />
                            {(product.install_url && installVideos.length == 0) && 
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Link to={product.install_url}>
                                        <GoogleImage 
                                            root 
                                            src="images/SignTypes/Install-Guide-thumb.jpg" 
                                            required 
                                            lazy
                                            alt="Installation Instructions" 
                                        />
                                    </Link>
                                    <Link to={product.install_url}>
                                        Installation Instructions
                                    </Link>
                                </div>
                            }
                        </div>
                        
                        <div style={{}}>
                        {installVideos.map((video, index) => (
                            <div key={index}>
                                <Layout2Columns align="top" padding={10}>
                                    <div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Link to={video.document_url}>
                                                <GoogleImage 
                                                    root 
                                                    src="images/SignTypes/Install-Guide-thumb.jpg" 
                                                    required 
                                                    lazy
                                                    alt="Installation Instructions" 
                                                />
                                            </Link>
                                            <Link to={video.document_url}>
                                                {video.title}
                                            </Link>
                                        </div>
                                    </div>

                                    <VideoPreview 
                                        video_url={"https://www.youtube.com/embed/" + video.video}
                                        image_url={video.image_url} 
                                        page_url={"/why/videos/" + video.name}
                                        size="large"
                                    />
                                </Layout2Columns>
                            </div>
                        ))}
                        </div>

                        <Layout2Columns align="top" padding={10}>
                            <div>
                            <Quote>
                                <div>
                                    <FontAwesomeSvgIcon icon={faUserCircle} />
                                    Charles Coooper
                                </div>
                                <p>
                                    "I did it all myself and i saved more than 50% than it would have cost from my local sign company. Seriously!!"
                                </p>
                                <div>
                                    <a href="https://www.trustpilot.com/reviews/5bf41e029d378009543d63dc" target="_blank">
                                        (read full testimonial)
                                    </a>
                                </div>
                            </Quote>
                            </div>
                            <div>
                            <Quote>
                                <div>
                                    <FontAwesomeSvgIcon icon={faUserCircle} />
                                    Austin
                                </div>
                                <p>
                                    "We honestly saved around $3000 dollars by using sign monkey and installing ourselves!"
                                </p>
                                <div>
                                    <a href="https://www.trustpilot.com/reviews/5a5fe42b6116dd0f1078d792" target="_blank">
                                        (read full testimonial)
                                    </a>
                                </div>
                            </Quote>  
                            </div>      
                        </Layout2Columns>                                                 
                    </ContentGrid>
                </Container>
            </Content3>

            <Content4>
                <Container width={900}>
                    <h2>
                        Shipping
                    </h2>
                    <Layout2Columns>
                        <div>
                            <ContentImage src={findContentImage("content/shipping/ups.jpg")} alt="Example UPS Box Shipment" />

                            <h4>UPS Shipment</h4>

                            <p>
                                All products small enough to fit into a box are shipped via UPS. We 
                                pack multiple items in each box and pack them to arrive in perfect condition. 
                                Products are shipped ready to install. You simply take the boxes to the installation 
                                site, carefully remove them from the packing and install. There is no assembly required.
                            </p>
                        </div>
                        <div>
                            <ContentImage src={findContentImage("content/shipping/freight.jpg")} alt="Example Shipping Crate" />
                                            
                            <h4>Truck Freight Shipping</h4>

                            <p>
                                When a product is too big to ship UPS, we will ship your item via truck 
                                freight. Your product will be ready to install directly from the crate. You simply take 
                                the crate to the installation location, unpack it, and install it onto the wall. Our 
                                crate is designed for your item to arrive in perfect condition.
                            </p>
                        </div>
                    </Layout2Columns> 
                </Container>
            </Content4> 

            <Content4>
                <Container width={900}>
                    <Layout2Columns>
                        <div>
                            <h2 style={{marginBottom: "0px"}}>
                                Product Photos
                            </h2>
                        </div>
                        <div style={{color: "#999", textAlign: "right"}}>
                            See more photos on our social media
                            
                            &nbsp;

                            <a href={social.facebookPageUrl} target="_blank">
                                <img src={FacebookIcon} height={35} width={35} alt="Facebook" />
                            </a>
                            <a href={social.instagramPageUrl} target="_blank">
                                <img src={InstagramIcon} height={35} width={35} alt="Instagram" />
                            </a>
                        </div>
                    </Layout2Columns>

                    <br />
                    
                    <PhotoGrid
                        product={product.name}
                    />
                </Container>
            </Content4>

            </Page>
        </>
    )
}

export const query = graphql`
    query($name: String!) {
        logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fixed(width: 175) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        products: allMysqlProducts(filter: {name: {eq: $name}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    short_title
                    subtitle
                    description
                    fulldescription
                    category,
                    category_name,
                    shipping_method_list
                    install_url
                    install_video_code
                    production_days
                }
            }
        }
        content: allMysqlProductContent(
                filter: {product_name: {eq: $name}, type: {eq: "construction"}}
                sort: { fields: list_order, order: ASC }
            ) {
            edges {
                content: node {
                    id
                    type
                    name
                    shortname
                    title
                    description
                    optional_ind
                    image_url
                    list_order
                }
            }
        }
        videos: allMysqlProductVideos(filter: {product_name: {eq: $name}}, limit: 3) {
            edges {
                video: node {
                    id
                    name
                    title
                    video_url
                    preview_thumb_url
                }
            }
        }
        installvideos: allMysqlInstallBlogPosts(filter: {product_name: {eq: $name}}) {
            edges {
                video: node {
                    id
                    name
                    title
                    video_url
                    image_url
                    document_url
                }
            }
        }
        images: allFile(filter: {relativeDirectory: {regex: "/content/"}}) {
            edges {
                content: node {
                    path: relativePath
                    image: childImageSharp {
                        fixed(width: 600, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }`