import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faArrowCircleLeft, faArrowCircleRight, faTimes } from "@fortawesome/free-solid-svg-icons"

import Overlay from "../Overlay"

import { storage, devices } from '../../settings'
import Loading from "../Loading"

const Gallery = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 3000000000 !important;
    box-sizing: border-box;

    div.top, div.bottom {
        position: fixed;
        left: 0px;
        right: 0px;
        width: 100%;
        padding: 10px;

        svg {
            cursor: pointer;
            color: rgba(255,255,255,.5);

            &:hover {
                color: white;
            }
        }
    }
    div.middle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5%;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
        font-size: 25px;

        img {
            max-width: 100%;
            max-height: calc(100vh - 20%);
            display: block;
            margin: auto;
        }
    }
    div.top {
        top: 0px;
        text-align: right;
    }
    div.bottom {
        bottom: 0px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }    
`

export default props => {
  const {photos, currentIndex} = props;

  const [index, setIndex] = useState(currentIndex || 0);
  const [photo, setPhoto] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");

  useEffect(
    () => {
        setLoaded(false);

        const image = new Image()

        image.onerror = (e) =>{
            setError("Error loading photo.");
            console.log("image error", e)
        };

        image.onload = function() {
            setError("");
            setLoaded(true);
            setPhoto(photos[index]);    
        }

        image.src = storage.url + photos[index].url;
    }, 
    [index]
  ); 

  function onPrev(e) {
    e.stopPropagation();
    e.preventDefault();

    if (index <= 0)
        setIndex(photos.length - 1)
    else
        setIndex(index - 1)
  }

  function onNext(e) {
    e.stopPropagation();
    e.preventDefault();

    if (index >= photos.length - 1)
        setIndex(0)
    else
        setIndex(index + 1)
  }

  return (
    <>
        <Overlay dark />    
        <Gallery
            onClick={props.onClose}
        >
            <div className="top">
                <FontAwesomeSvgIcon icon={faTimes} onClick={props.onClose} size="3x" />
            </div>
            <div className="middle">
                {(photo && loaded) ?
                    <img 
                        src={storage.url + photo.url} 
                        alt={photo.title} 
                    />
                : (error.length > 0) ? 
                    <div style={{color: "red"}}>{error}</div>
                :
                    <Loading />
                }
            </div>
            {(photos.length > 1) && 
                <div className="bottom">
                    <FontAwesomeSvgIcon 
                        icon={faArrowCircleLeft} 
                        size="5x" 
                        onClick={onPrev}
                    />      
                    <FontAwesomeSvgIcon 
                        icon={faArrowCircleRight} 
                        size="5x" 
                        onClick={onNext}
                    />
                </div>
            }
        </Gallery>
    </>
  )
}
