import React from "react"
import styled from "styled-components"

import { parseOutVideoId } from "../utilities/utils"

const VideoContainer = styled.div`
  > div {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe{
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
`

function Video(props) {
  const videoUrl = props.video_url ? "https://www.youtube.com/embed/" + parseOutVideoId(props.video_url) + "?autoplay=0" : null;

  return (
    <VideoContainer className="video">
        <div>
            <iframe src={videoUrl} width="420" height="315" title={props.title} frameBorder='0' allowFullScreen></iframe>
        </div>
    </VideoContainer>
  );
}

export default Video