import React from "react"
import styled from "styled-components"

import { storage } from "../settings"

const Box = styled.div`
    background-image: url(${props => props.local ? "":storage.url}${props => encodeURI(props.src + "?v2")});
    background-position: center;
    background-size: cover;
    width: ${props => props.width}${props => Number.isInteger(props.width) ? "px":""};
    height: ${props => props.height}${props => Number.isInteger(props.height) ? "px":""};
`

function ImageBox(props) {
    return (
      <Box {...props} title={props.alt}>
          &nbsp;
      </Box>
    );
}

export default ImageBox