import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageBox from "./ImageBox"
import Loading from "./Loading"
import HoverButton from "./buttons/HoverButton";
import PhotoGalleryDialog from "./dialogs/PhotoGalleryDialog"

import { social, colors, storage, devices } from '../settings'
import { postData } from "../services/server"

import FacebookIcon from '../images/icons/facebook.png'
import InstagramIcon from '../images/icons/instagram.png'
import { removeNulls } from "../utilities/utils"

const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Photo = styled.div`
  flex: 0;
  flex-basis: 25%;
  border: 1px solid #eee;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 150px;
  text-align: center;
  overflow: hidden;
  transition: all .2s ease-in-out;
  cursor: pointer;

  @media ${devices.mobile} {
    flex-basis: 49%;
  }  

  &:hover {
    transform: scale(1.025);
    box-shadow: 0 5px 5px rgba(0,0,0,0.3);
  }

  img {
    border-radius: 3px;
  }
  
  &.social {
    box-sizing: border-box;
    padding: 10px;
    background-color: ${colors.lightGrey};
  
    img {
      height: 50px;
      width: 50px;
      margin: 5px;
    }  
  }
  &.more {
    font-size: 18px;
    color: #999;
    padding: 50px 20px;
    text-align: center;
    flex-basis: 100%;
    height: auto;
    margin: 10px auto;
    max-width: 200px;
    border-color: ${colors.orange};
    color: ${colors.orange};
    padding: 10px;

    &:hover {
      color: white;
      background-color: ${colors.orange};
    }
  }
`
const NotFound = styled.div`
  color: #ccc;
  font-size: 18px;
  text-align: center;
  margin: 50px;
`
const Pagination = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-size: 16px;

  > div {
    margin: 0px 20px;
  }

  button {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 3px;
    width: 120px;
  }
`

const PhotoGrid = (props) => {
  const gridRef = useRef();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(-1);
  const [open, setOpen] = useState(false);

  // const data = useStaticQuery(graphql`
  //     {
  //       photos: allMysqlPhotos(sort: {fields: added_date, order: DESC}) {
  //           edges {
  //               photo: node {
  //                   id
  //                   url
  //                   regular: url,
  //                   thumbnail: url_thumb
  //                   image_width
  //                   image_height
  //                   title
  //                   categories
  //                   keywords
  //               }
  //           }
  //       }
  //     }
  // `);
  
  const [photos, setPhotos] = useState([])
  const [activePhotos, setActivePhotos] = useState(null)
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const perPage = 50;

  useEffect(
    () => {
      setLoading(true);

      postData("/api/photos/all", removeNulls({
        category: props.category || null,
        product: props.product || null,
        page: props.page || null,
      }),
          function(response) {
              setPage(1);
              setPhotos(response);
          },
          function(error) {
              window.alert("An error occured loading the photos.");
          },
          function() {
            setLoading(false);
          }
      );        
    }, 
    [props.category]
  ); 

  // useEffect(
  //   () => {
  //     var options = {
  //       shouldSort: true,
  //       tokenize: true,
  //       findAllMatches: false,
  //       threshold: 0.6,
  //       location: 0,
  //       distance: 100,
  //       maxPatternLength: 32,
  //       minMatchCharLength: 2,
  //       keys: [
  //         "photo.categories"
  //       ]
  //     };

  //     let photos = [];

  //     // if we have a sort, then pre-filter 
  //     if (props.sort !== null && props.sort !== "") 
  //       photos = data.photos.edges.filter(x => x.photo.categories && x.photo.categories.indexOf(props.sort) !== -1).map(x => x.photo);
  //     else 
  //       photos = data.photos.edges.filter(x => x.photo.categories).map(x => x.photo);

  //     //if (props.categories && props.categories.length) {
  //     //  const fuse = new Fuse(photos, options); // "list" is the item array
  //     //  const result = fuse.search(props.keyword);

  //     //  setPhotos(result);
  //     //}
  //     //else {
  //       setPage(1);
  //       setPhotos(photos)
  //     //}

  //     // always start on the first page
  //     //setPage(1);
  //     // slice off the first page
  //     //setActivePhotos(photos.slice(0, 1*perPage))
  //   }, 
  //   [props.keyword, props.sort]
  // );

  useEffect(
    () => {
      let pages = photos ? (photos.length / perPage).toFixed() : 1;

      if (pages * perPage < photos.length) pages++;

      setPages(pages);
    }, 
    [photos]
  );

  useEffect(
    () => {
      let start = (page-1)*perPage;
      let end = start + perPage;

      if (end > photos.length) end = photos.length;

      // slice off the first page
      const temp = photos.slice(start, end);//(0, page*perPage);

      temp.forEach( (photo) => {
        photo.src = storage.url + photo.url + "?v2";
      })

      if (props.social && page == 1 && temp.length > 0)
        temp.splice(3, 0, {id: 0})

      setActivePhotos(temp)
    }, 
    [photos, page]
  );
    
  useEffect(
    () => {
      setOpen(selected >= 0);
    }, 
    [selected]
  );

  function toggleLightbox(index) {
    if (props.social && page == 1 && index > 3)
      index--;

    setSelected(index);
  }

  function onPrevPage() {
    if (page > 1)
      onPage(page - 1);
  }
  function onNextPage() {
    if (page < pages)
      onPage(page + 1);
  }

  function onPage(page) {
    setPage(page);
    //gridRef.current.scrollIntoView({behavior: 'smooth'});
    //window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll({top: gridRef.current.offsetTop - 100, left: 0, behavior: 'smooth' })
  }

  const pageOptions = [];

  for (let i=1; i<=pages; i++) {
    pageOptions.push(`<option key='${i} value='${i}' ` + (page == i ? "selected":"") + `>${i}</option>`)
  }

  return (
      <>
        {loading ?
          <Loading />
        :
          <>
            <Photos ref={gridRef}>
                {activePhotos && activePhotos.length > 0 
                  ?
                    <>
                      {activePhotos.map((photo, index) => {
                        const isSocial = photo.id == 0;

                        return (
                          <Photo key={index} onClick={() => !isSocial ? toggleLightbox(index) : null} className={isSocial ? "social" : ""}>
                            {isSocial ? 
                              <>
                                <p>See more signs on our social media</p>
                                <div>
                                  <a href={social.facebookPageUrl} target="_blank">
                                    <img src={FacebookIcon} alt="Facebook" />
                                  </a>
                                  <a href={social.instagramPageUrl} target="_blank">
                                    <img src={InstagramIcon} alt="Instagram" />
                                  </a>
                                </div>
                              </>
                            :
                              <ImageBox src={photo.thumbnail || photo.url} width="100%" height={150} alt={photo.title} />
                            }
                          </Photo>
                      )})}
                    </>
                  : activePhotos === null ?
                    <Loading label="Loading photos..." style={{ margin: "50px" }} />
                  :
                    <NotFound>No photos found.</NotFound>
                }           
            </Photos>

            {(pages > 1) && 
              <Pagination>
                <HoverButton 
                  icon={faArrowLeft}
                  label="Previous"
                  onClick={onPrevPage} 
                  disabled={page <= 1}
                />
                <div>
                  {photos.length} Photos, 
                  Page 
                  &nbsp;            
                  <select 
                    name="page" 
                    onChange={(e) => onPage(e.target.value)} 
                    style={{
                      fontSize: "100%",
                      padding: "7px",
                      borderRadius: "3px",
                      borderColor: "#ccc"                  
                    }}
                    dangerouslySetInnerHTML={{ __html: pageOptions}} 
                  />
                  &nbsp;            
                  of
                  &nbsp;            
                  {pages}
                </div>
                <HoverButton 
                  icon={faArrowRight}
                  iconPos="right"
                  label="Next"
                  onClick={onNextPage} 
                  disabled={page >= pages}
                />
              </Pagination>
            }
          </>
        }

        {open &&
          <PhotoGalleryDialog 
            photos={activePhotos.filter(p => p.id != 0)}
            currentIndex={selected}
            onClose={() => {
              setOpen(false);
              setSelected(-1);
            }}
          />
        }
      </>
  )
}

export default PhotoGrid